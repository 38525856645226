import $ from 'jquery';
import 'readmore-js';

$.ajaxSetup({
    cache: true,
});

window.$ = window.jQuery = $;

export default $;
