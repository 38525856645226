import $ from './vendor/jquery';
import Aos from 'aos';
import './vendor/typo3';

// Scroll animations
Aos.init({
  duration: 600,
});

// floating label

var float = $('.floating-label');

float.on('focus', function() {
  $(this).siblings('label').addClass('focused');
});
float.on('focusout', function() {
  if ($(this).val() === '') {
    $(this).siblings('label').removeClass('focused');
  }
});


// Function to initialize readmore
function initializeReadMore($elements) {
  var moreLinkLabel, lessLinkLabel;

  if ('de' === $('html').attr('lang')) {
    moreLinkLabel = 'weiterlesen';
    lessLinkLabel = 'schließen';
  } else {
    moreLinkLabel = 'read more';
    lessLinkLabel = 'close';
  }

  $elements.readmore({
    collapsedHeight: 19.6 * 3, // Three lines of a regular paragraph
    moreLink: '<a href="#">' + moreLinkLabel + '</a>',
    lessLink: '<a href="#">' + lessLinkLabel + '</a>',
    embedCSS: false,
  });
}



// background color change

$(window).scroll(function() {

  // selectors
  var $window = $(window),
    $body = $('body'),
    $panel = $('.panel[data-color]');

  // Change 50% earlier than scroll position so colour is there when you arrive.
  var scroll = $window.scrollTop() + ($window.height() / 2);

  // Variable to store the color of the last element which matched our criteria
  var newColor = null;

  // Get the color of the last element in the list which offset is below scroll-offset + 50% of viewport-height
  $panel.each(function () {
    var $this = $(this);

    if ($this.position().top <= scroll) {
      newColor = $this.data('color');
    }
  });

  if (newColor) {
    // Remove all classes on body with bg-
    $body.removeClass(function (index, css) {
      return (css.match (/(^|\s)bg-\S+/g) || []).join(' ');
    });

    // Add class of currently active div
    $body.addClass('bg-' + newColor);
  }

}).scroll();





// initial background color based on background of first panel

$(".panel:first-of-type[data-color='green']").parent("body").addClass("bg-green");
$(".panel:first-of-type[data-color='blue']").parent("body").addClass("bg-blue");
$(".panel:first-of-type[data-color='yellow']").parent("body").addClass("bg-yellow");
$(".panel:first-of-type[data-color='violet']").parent("body").addClass("bg-violet");
$(".panel:first-of-type[data-color='white']").parent("body").addClass("bg-white");






// newsletter success popup

jQuery(document).ready(function($){
  //open popup
  $('.newsletter-popup-trigger').on('click', function(event){
    event.preventDefault();
    $('.newsletter-popup').addClass('is-visible');
  });

  //close popup
  $('.newsletter-popup').on('click', function(event){
    if( $(event.target).is('.newsletter-popup-close') || $(event.target).is('.newsletter-popup') ) {
      event.preventDefault();
      $(this).removeClass('is-visible');
    }
  });
  //close popup when clicking the esc keyboard button
  $(document).keyup(function(event){
    if(event.which=='27'){
      $('.newsletter-popup').removeClass('is-visible');
    }
  });
});






// rotating logo

;(function() {
  var throttle = function(type, name, obj) {
    var obj = obj || window;
    var running = false;
    var func = function() {
      if (running) { return; }
      running = true;
      requestAnimationFrame(function() {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };
  throttle ("scroll", "optimizedScroll");
})();

var logo = document.getElementById("logo");

// to use the script *without* anti-jank, set the event to "scroll" and remove the anonymous function.

window.addEventListener("optimizedScroll", function() {
  logo.style.transform = "rotate("+window.pageYOffset+"deg)";
});






// toggle nav
$('.burger, .overlay').click(function(){
  $('.burger').toggleClass('clicked');
  $('.overlay').toggleClass('show');
  $('nav').toggleClass('show-nav');
  $('.has-subnav').removeClass('show-subnav');
  $('.show-nav').removeClass('showing-subnav');
  $('.nav-back-links').width(0);
  $('body').toggleClass('overflow');
  window.setTimeout(function () {
    $('.nav-back-links').empty();
  }, 600);
});


// toggle subnav
/*$('.has-subnav > a, .close-subnav').click(function(event){ // click element with subnav
  event.preventDefault();
  $(this).parent().toggleClass('show-subnav'); // toggle subnav
  $('.has-subnav a').not(this).parent().removeClass('show-subnav'); // hide all other subnavs
  // move mainnav if subnav is open
  if( $('.has-subnav').hasClass('show-subnav') ) {
    $('nav').addClass('showing-subnav');
  } else {
    $('nav').removeClass('showing-subnav');
  }
});*/
// main menu
(function () {
  const $nav = $('#main-nav');
  const $backLinks = $('#main-nav-back-links');

  $backLinks.width(0);

  $nav.on('click', '.has-subnav > a', function (event) {
    event.preventDefault();

    const $clickedLink = $(event.currentTarget);

    // Close all other menus on the same level
    $clickedLink.closest('ul').find('> .show-subnav').removeClass('show-subnav');

    $clickedLink.parent().addClass('show-subnav');
    $nav.addClass('showing-subnav');

    const $backLink = $('<a href="#" />')
      .text($clickedLink.data('backLinkLabel'))
      .data('menuItem', $clickedLink.parent())
      .appendTo($backLinks);
    if ($clickedLink.data('linkColor')) {
      $backLink.css('color', $clickedLink.data('linkColor'));
    }
    if ($clickedLink.data('backgroundColor')) {
      $backLink.css('background-color', $clickedLink.data('backgroundColor'));
    }

    const newWidth = $backLink.outerWidth(true) * $backLinks.find('a').length;
    $backLinks.width(newWidth);
  });

  $backLinks.on('click', 'a', function (event) {
    event.preventDefault();

    const $links = $backLinks.find('a');
    const level = $links.index(event.currentTarget);
    const $toClose = $links.slice(level);

    const newWidth = $links.first().outerWidth(true) * ($links.length - $toClose.length);
    $backLinks.width(newWidth);

    $toClose.each(function () {
      const $link = $(this);
      const $menuItem = $link.data('menuItem');
      if ($menuItem) {
        $menuItem.removeClass('show-subnav');
        if (0 === $nav.find('.show-subnav').length) {
          $nav.removeClass('showing-subnav');
        }

        window.setTimeout(function () {
          $link.remove();
        }, 600);
      }
    });
  });
})();



// accordion

var accordion = (function(){

  var $accordion = $('.accordion');
  var $accordion_header = $accordion.find('.accordion-header');
  var $accordion_item = $('.accordion-item');

  // default settings
  var settings = {
    // animation speed
    speed: 300,

    // close all other accordion items if true
    oneOpen: false
  };

  return {
    // pass configurable object literal
    init: function($settings) {
      $accordion_header.on('click', function() {
        accordion.toggle($(this));
      });

      $.extend(settings, $settings);

      // ensure only one accordion is active if oneOpen is true
      if(settings.oneOpen && $('.accordion-item.active').length > 1) {
        $('.accordion-item.active:not(:first)').removeClass('active');
      }

      // reveal the active accordion bodies
      $('.accordion-item.active').find('> .accordion-body').show();
    },
    toggle: function($this) {

      if(settings.oneOpen && $this[0] != $this.closest('.accordion').find('> .accordion-item.active > .accordion-header')[0]) {
        $this.closest('.accordion')
          .find('> .accordion-item')
          .removeClass('active')
          .find('.accordion-body')
          .slideUp()
      }

      // show/hide the clicked accordion item
      $this.closest('.accordion-item').toggleClass('active');
      $this.next().stop().slideToggle(settings.speed);

      if ($this.closest('.accordion-item').hasClass('active')) {
        initializeReadMore($this.next().find('.read-more'));
      }
    }
  }
})();

$(document).ready(function(){
  accordion.init({ speed: 300, oneOpen: false });
});

// Lazy-Loading for news
jQuery(document).ready(function ($) {
  $('.load-more-container').each(function () {
    var $container = $(this),
      $list = $container.find('.load-more-list'),
      $chunks = $container.find('.load-more-chunk'),
      totalChunks = $chunks.length,
      visibleChunks = $list.children().length,
      $button = $container.find('.load-more'),
      showNextChunk = function () {
        var $nextChunk = $chunks.eq(visibleChunks);
        if (0 < $nextChunk.length) {
          $list.append($nextChunk.html());
          ++visibleChunks;
        }
      };

    showNextChunk();

    if (visibleChunks < totalChunks) {
      $button
        .attr('hidden', false)
        .on('click', function (event) {
          event.preventDefault();

          showNextChunk();

          if (visibleChunks === totalChunks) {
            $button.attr('hidden', true);
          }
        });
    }
  });
});

// Read more
jQuery(document).ready(function ($) {
  initializeReadMore($('.read-more'));
});

// Cookie-Consent
$('body').on('click', '.show-cookie-settings', function (event) {
  if ('undefined' !== typeof klaro) {
    event.preventDefault();

    klaro.show();
  }
});
